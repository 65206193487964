.App {
  text-align: center;
  overflow-x: hidden;
  padding:50px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

*{
  font-family: 'Montserrat', sans-serif !important;
}
.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.App-header{
  display: flex;
  flex-direction: column;
}
.MuiDialog-paperScrollPaper{
  padding:10px;
}
#customized-dialog-title{
  font-size: 20px;
}
.cnt{
  width: 158px;
  height: 10px;
  background-repeat: round;
  background-size: cover;
}
.content1{
  background-image: url(green3.jpg);
  }
.content2{
  background-image: url(Yellow2.jpg);
}
.content3{
  background-image: url(green4.jpg);

}
.content4{
  background-image: url(Yellow11.jpg);
}
.content5{
  background-image: url(Orange2.jpg);
}
.content6{
background-image: url(green2.jpg);

}
.content10{
  background-image: url(borderline.png);
}
.content7{
background-image: url(Yellow3.jpg);
}
.content8{
background-image: url(Orange1.jpg);
}
.content9{
background-image: url(green.jpg);
}

.MuiDialogContent-dividers{
  border:0px !important
}
.MuiDialogContent-root{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  }

.inittext{
  color:gray;
  font-size: small;
}

.MuiDialogContent-root>p{
    margin-bottom: 0 !important;
  }

.cnts{
  z-index: 1;
  bottom: 0;
  position: relative;
}
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.btl{
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
}
.cnt{
  transition: font-size 0.2s;
  transition-timing-function: ease;
  color: black;
}
.btns{
  margin-top: 80px;
  color: black;
  width: 100%;
}
.btn{
  margin-left: 30px;
  cursor: pointer;
}
.btnimg{
  position: relative; 
}
.btntex{
  position: absolute; 
  top: -23px; 
  left: 0; 
  width: 100%;
}
.results{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.resultstext{
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
  align-items: flex-start  !important;
}
.resultstext>p{
  margin-bottom: 0  !important;
  margin-top: 15px !important;

}
.results>div{
  margin: 10px;
}
.results>div>p{
  width: 300px;
  font-size: 20px !important;
  text-align: right;
}
.cnts{
  transition: margin-top 0s;
  transition-timing-function: ease;
}
.filelr{
  position: relative;
}
.MuiSlider-rail, .MuiSlider-track{
  z-index: 0 !important;
  position: relative;
  display: none !important;
}
.bottlecont{
  display: flex;
  flex-direction: row;

}
.MuiSlider-track, .MuiSlider-rail{
  display: none !important;
}
.MuiSlider-root.MuiSlider-vertical {
  width: 0px !important;}

.slidercont{
  height: 236px;
  position: absolute;
  left: calc(50% + 80px);
  margin-top: 205px;
  margin-left: 15px;
}
.MuiSlider-thumb {
  color:black !important;
  width:15px !important;
  height: 15px !important;
  pointer-events: all !important

}
.MuiSlider-vertical .MuiSlider-thumb {
z-index: 10 !important;
}
.MuiSlider-root.MuiSlider-vertical {
  padding: 0 0px !important;
  }
.MuiSlider-root{
  pointer-events: none !important
}
.MuiButton-outlined {
  border-radius: 0 !important;
  border: 2px solid rgba(0, 0, 0, 1) !important;
  color: black !important;
  margin-bottom: 10px !important;
  margin-right: 10px !important;
  margin-top: 10px !important;
  padding:5px 30px!important;
}

.MuiButton-contained{
  border-radius: 0 !important;
  border: 2px solid rgba(0, 0, 0, 1) !important;
  background-color: black !important;
  padding:5px 30px!important;

}
.MuiButton-contained>.MuiButton-label{
  color:white !important
}
.morebtn{
  width:100%;
}
.karas{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  width: 400px;
}

.overlay{
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  background-color: black;
  display: none;
}
.dots>*{
  cursor: pointer;
}

.overlay>p{
  color: white;
margin-top: 50vh;}
.contentf{
  background-color: #F8F5EC;
}
.bottleimg{
  width:160px;
}


.notice{
  text-align: left;
  font-size: 10px;
  margin-top: -75px;
  color: red;
  width:fit-content;
  left: calc(50% + 110px);
  position: relative;
    opacity: 1;
  transition: all 0.3s;
}
.hiddennotice{
  opacity: 0;
}
.prbtn{
  margin-top: 20px !important;
}
@media only screen and (max-width: 920px) {
  .ingtext{
    display: none;
  }
  .cntdiv[style]{
    margin-left: 55px !important;
  }
  .notice{
    margin-top: 0;
    text-align: center;
    width: 100%;
    left: 0;
    font-size: 13px;

  }
  .results {
    display: flex;
    flex-direction: column;
  }
  
  .results>div {
    width: 100%;

  }
  .results>div>p {
    width: 100%;
    font-size: 20px !important;
    text-align: center;

}
.cnt{
  transition: all 0s;
}
.dots1[style]{
  margin-top:-100px !important;
}
.dots2[style]{
  margin-top:-12px !important;

}
.dots3[style]{
  margin-top:-15px !important;

}
.btncont>*{
  width: 270px;
}
.btncont{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.MuiButton-outlined {
  margin-right: 0 !important;
}
}